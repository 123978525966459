<template>
  <div
    ref="dropdownRef"
    :class="{
      'osk-dropdown': true,
      'osk-dropdown_disabled': disabled,
    }"
  >
    <div
      class="osk-dropdown__activator"
      @click="toggleDropdown"
    >
      <UikitLoaderInner v-if="loading" />

      <slot name="activator" />
    </div>

    <UikitTransitionCollapse
      :show="isActive && !disabled"
      :class="{
        'osk-dropdown__collapse': true,
        'osk-dropdown__collapse_shadow': shadow,
        'osk-dropdown__collapse_shadow-select': shadowSelect,
        'osk-dropdown__collapse_pos-right': position === 'right',
      }"
    >
      <slot />
    </UikitTransitionCollapse>
  </div>
</template>

<script lang="ts" setup>
import { onClickOutside } from '@vueuse/core';

type TPosition = 'right'

let removeClickOutside: (() => void) | undefined;

const props = defineProps<{
  modelValue?: boolean,
  loading?: boolean
  shadow?: boolean
  shadowSelect?: boolean
  disabled?: boolean
  position?: TPosition
}>();

const emit = defineEmits<{
  (e: 'update:modelValue', value: boolean): void
}>();

const dropdownRef = ref<HTMLElement>();

const isActive = computed<boolean>(() => props.modelValue ?? false);

function handleDropdown() {
  if (isActive.value) {
    removeClickOutside = onClickOutside(dropdownRef, toggleDropdown);
  } else if (removeClickOutside) {
    removeClickOutside();
  }
}

function toggleDropdown() {
  update(!isActive.value);
}

function show() {
  update(true);
}

function hide() {
  update(false);
}

function update(value: boolean) {
  emit('update:modelValue', value);
}

defineExpose({
  show,
  hide,
});

watch(() => props.modelValue, (value) => {
  handleDropdown();
});
</script>

<style scoped lang="scss">
@import "~/assets/scss/settings/index";

.osk-dropdown {
  position: relative;

  &__collapse {
    min-width: 100%;
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1;
    border: 1px solid #e6e6e6;
    background-color: $white;

    &_shadow {
      box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
    }

    &_shadow-select {
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.06);
      width: calc(100% + 8px);
      transform: translate(-4px, -2px);
    }

    &_pos-right {
      left: auto;
      right: 0;
    }
  }

  &__activator {
    cursor: pointer;
  }

  :deep(.osk-dropdown-list) {
    position: static;
    border: 0;
  }

  :deep(.osk-loader) {
    width: 20px;
    height: 20px;
    border-top-width: 2px;
    border-right-width: 2px;
    opacity: .7;
  }

  &_disabled {
    .osk-dropdown__activator {
      cursor: default;
    }
  }
}
</style>
