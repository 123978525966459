<template>
  <form
    ref="$form"
    @submit="onSubmit"
  >
    <slot />
  </form>
</template>

<script setup lang="ts">
import { useForm } from 'vee-validate';

import { scrollToFirstError } from '@/utils/scroll';

import type { TSubmitSuccess, TSubmitErrors } from '~/types/components/form';
import '~/utils/validationRules';

const props = defineProps<{
  initialValues?: Record<string, string>
  initialErrors?: Record<string, string>
  scrollToError?: boolean
  noValidate?: boolean
}>();

const emit = defineEmits<{
  (e: 'submit', value: Event): void,
  (e: 'submitValidateSuccess', data: TSubmitSuccess<any>): void,
  (e: 'submitValidateError', errors: TSubmitErrors): void,
}>();

const $form = ref<HTMLElement>();

const {
  handleSubmit, setFieldError, isSubmitting, resetForm, validate,
} = useForm({
  initialValues: props.initialValues,
  initialErrors: props.initialErrors,
});

const onSubmit = props.noValidate ? submitNoValidate : handleSubmit((values, actions) => {
  emit('submitValidateSuccess', { values, actions });
}, async (errors) => {
  if (props.scrollToError) {
    scrollToFirstError($form.value);
  }

  emit('submitValidateError', errors);
});

function submitNoValidate(e: Event) {
  emit('submit', e);
}

defineExpose({
  setFieldError,
  isSubmitting,
  submit: onSubmit,
  resetForm,
  validate,
});
</script>
