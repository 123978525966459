<template>
  <UikitFormField
    ref="$field"
    :class="{
      'osk-select': true,
      'osk-select_opened': isOpened,
    }"
    :model-value="modelValue"
    :name="name"
    :label="label"
    :rules="rules"
    :required="required"
    :no-error-message="noErrorMessage"
    @update:model-value="updateField"
  >
    <UikitDropdown
      v-model="isOpened"
      class="osk-select__dropdown"
      :disabled="disabled || loading || loadingInner"
      shadow-select
    >
      <template #activator>
        <div class="osk-select__content">
          <template v-if="!multiple">
            <input
              v-if="searchRequest || useLocalSearch"
              v-model="searchValue"
              :placeholder="placeholder"
              class="osk-select__input"
              :disabled="disabled ?? false"
              @focus="onFocus"
              @input="handleInput"
              @blur="onBlur"
            >

            <slot
              v-else
              name="selected-item"
              :item="selectedItems[0]?.value"
            >
              <img
                v-if="selectedItems[0]?.value?.imageUrl"
                class="osk-select__image"
                :src="selectedItems[0]?.value?.imageUrl"
                :alt="selectedItems[0]?.label"
                :title="selectedItems[0]?.label"
              >
              <div class="osk-select__input">
                {{ selectedItems[0]?.label || placeholder }}
              </div>
            </slot>
          </template>

          <UikitLoader
            v-if="loading || loadingInner"
            class="osk-select__loader"
            small
          />

          <UikitIcon
            v-else-if="!hideDropdownIcon && !disabled"
            name="ChevronDown"
            class="osk-select__icon"
          />
        </div>
      </template>

      <UikitMenu
        :max-height="maxHeight"
        class="osk-select__list"
      >
        <slot />
      </UikitMenu>
    </UikitDropdown>
  </UikitFormField>
</template>

<script setup lang="ts">
import isEqual from 'lodash.isequal';
import { isHTMLInputElement } from '~/utils/html';
import type { FieldContext } from '~/types/components/form';

import { TSelectedItem, selectKey } from '~/types/components/select';

const props = withDefaults(defineProps<{
  name: string
  search?: string
  modelValue?: any
  label?: string
  disabled?: boolean
  placeholder?: string
  rules?: string
  required?: boolean
  maxHeight?: string
  noErrorMessage?: boolean,
  loading?: boolean,
  multiple?: boolean, // TODO сделать мультиселект
  searchRequest?: (value?: string) => Promise<unknown>,
  optionLabel?: string,
  hideDropdownIcon?: boolean,
  useLocalSearch?: boolean
}>(), {
  optionLabel: 'label',
});

const emits = defineEmits<{
  (e: 'update:modelValue', value: any): void,
  (e: 'update:search', value?: string): void,
  (e: 'blur'): void,
  (e: 'on:local-search', value: any): void,
  (e: 'on:input', value: any): void,
}>();

const slots = useSlots();

const $field = ref<FieldContext>();
const isOpened = ref(false);
const searchValue = ref(props.modelValue ? props.modelValue[props.optionLabel] : '');
const loadingInner = ref(false);
const isFocused = ref(false);

function setValue(value: any) {
  if (!$field?.value?.setValue) return;

  $field.value.setValue(value);
}

const options = computed<TSelectedItem[]>(() => {
  if (!slots.default) return [];

  return slots.default()[0]?.children?.map((item) => ({
    value: item.props.value,
    label: item.props.label,
  })) ?? [];
});

const selectedItems = computed<TSelectedItem[]>(() => options.value.filter((o) => isEqual(o.value, props.modelValue)) ?? []);

function updateField(value: any) {
  if (!props.multiple) {
    emits('update:modelValue', value);
  }
}

function handleOptionClick(item: TSelectedItem) {
  if (!$field?.value?.setValue) return;

  if (!props.multiple) {
    setValue(item.value);

    if (props.searchRequest || props.useLocalSearch) {
      isFocused.value = true;
      searchValue.value = item.label;
    }
  }
  isOpened.value = false;
}

function onFocus() {
  if (isFocused.value) {
    isFocused.value = false;
    return;
  }

  if (!isOpened.value && (props.searchRequest || props.useLocalSearch)) {
    loadOptions(searchValue.value);
  } else {
    isOpened.value = false;
  }
}

function onBlur() {
  isOpened.value = false;
  emits('blur');

  if (searchValue.value) {
    searchValue.value = isDefined($field?.value?.value) ? $field?.value.value[props.optionLabel] : '';
  } else {
    emits('update:modelValue', undefined);
    setValue(undefined);
  }
}

const handleInput = useDebounceFn((e: Event) => {
  if (!isHTMLInputElement(e.target)) return;

  const value = e.target.value?.trim() ?? '';

  isOpened.value = true;

  loadOptions(value);

  emits('on:input', e);
}, 300);

async function loadOptions(value?: string) {
  if (props.useLocalSearch) {
    emits('on:local-search', value);
    return;
  }

  if (!props.searchRequest) return;

  loadingInner.value = true;

  await props.searchRequest(value ?? '');

  loadingInner.value = false;
}

defineExpose({
  searchValue,
  setValue,
});

provide(selectKey, {
  selectedItems,
  handleOptionClick,
});
</script>

<style scoped lang="scss">
@import "~/assets/scss/settings/index";

.osk-select {
  &__dropdown {
    width: 100%;
  }

  &__content {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    align-items: center;
  }

  &__image {
    margin-left: 16px;
    width: 24px;
    height: 24px;
  }

  &__input {
    outline: 0;
    border: 0;
    width: 100%;
    font-size: $font-size-body;
    height: 42px;
    line-height: 42px;
    padding: 0 12px;
    cursor: pointer;
    padding-right: 32px;
    text-overflow: ellipsis;
  }

  &__icon,
  &__loader {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 6px;
    transition: all linear .2s;
  }

  &__loader {
    margin-top: -10px;
  }

  :deep(.osk-field__inner) {
    padding: 0;
  }

  :deep(.osk-dropdown__collapse) {
    box-sizing: content-box;
    left: -1px;
    border: 0;
  }

  &_opened {
    .osk-select__icon {
      transform: translateY(-50%) rotate(180deg);
    }
  }
}
</style>
