<template>
  <div
    class="osk-menu"
    :style="styles"
  >
    <slot />

    <div
      v-if="!slots.default || !slots.default()[0]?.children?.length"
      class="osk-menu__empty"
    >
      Список пуст
    </div>
  </div>
</template>

<script setup lang="ts">
const props = defineProps<{
  maxHeight?: string
}>();

const slots = useSlots();

const styles = computed(() => (props.maxHeight ? `max-height: ${props.maxHeight};` : ''));
</script>

<style scoped lang="scss">
@import "~/assets/scss/settings/index";

.osk-menu {
  background-color: #fff;
  overflow: auto;
  max-height: 250px;
  border-radius: $radius-small;
  padding: $indent-compact 0;

  &__empty {
    @include font-style($font-size-caption, $line-height-caption, $font-weight-medium);
    padding: 6px $indent-medium;
    text-align: center;
  }
}
</style>
