<template>
  <UikitMenuItem
    :active="isItemActive"
    @click="handleClick"
  >
    <slot>
      <span>{{ currentLabel }}</span>
    </slot>
  </UikitMenuItem>
</template>

<script setup lang="ts">
import isEqual from 'lodash.isequal';
import { selectKey } from '~/types/components/select';

const props = defineProps<{
  value: any
  label?: string
}>();

const $select = inject(selectKey);

const isObject = computed(() => Object.prototype.toString.call(props.value).toLowerCase() === '[object object]');

const currentLabel = computed(() => props.label ?? (isObject.value ? '' : props.value));

const isItemActive = computed(() => {
  if (!$select?.selectedItems.value?.length) return false;

  return !!$select.selectedItems.value.find((item) => isEqual(item.value, props.value));
});

const item = computed(() => ({
  value: props.value,
  label: currentLabel.value,
}));

function handleClick() {
  if (!$select) return;

  $select.handleOptionClick(item.value);
}
</script>

<style scoped lang="scss">
@import "~/assets/scss/settings/index";
</style>
