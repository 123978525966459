import { InjectionKey, Ref, ComputedRef } from 'vue';

export type TName = string;
export type TModel = TName | TName[];

export type TSelectedItem = {
  label: string
  value: any
}

interface SelectContext {
  selectedItems: Ref<TSelectedItem[]>
  handleOptionClick: (item: TSelectedItem) => void
}

export const selectKey: InjectionKey<SelectContext> = Symbol('cselectContextKey');
